.upload-source-data-modal-window {
    display: flex;
    width: 90vw;
    min-width: 1400px;
    height: 70vh;
    gap: 3rem;
    justify-content: space-around;
}

.existing-source-dataset-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 2%;
}


.source-dataset-container-header {
    margin-bottom: 0 !important;
}

.upload-source-data-assign-btn {
    margin-left: auto !important;
    margin-bottom: 2% !important;
}

.upload-source-table-container {
    justify-self: center !important;
}

.new-source-dataset-container {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.new-source-dataset-upload-file-container {
    width: 93%;
    margin-top: 1%;
    border: 1px solid #757272;
    padding: 1% 0 1% 3%;

}

.new-source-dataset-dropzone-area {
    margin: 0 auto;
    width: 50% !important;
}

.new-source-dataset-tooltip {
    margin-right: 1%;
    width: 24px;
    height: 24px;
}

.dropzone-file-container {
    display: flex;
    flex-direction: column;
}

.new-source-dataset-form-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    min-width: 500px;
}

.source-data-modal-close-and-save-btn {
    width: 10%;
    margin-left: auto !important;
    margin-right: 2% !important;
    margin-bottom: 1% !important;
}

.upload-source-data-connect-db-container {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    margin-top: 0.5rem;
}

.upload-source-data-connect-db-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}