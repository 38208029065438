.manage-notes-main {
    width: 70vw;
    display: flex;
    flex-direction: row;
    height: 75vh;
}

.manage-notes-template-object-container {
    width: 25%;
    padding: 1rem;
}

.manage-notes-template-object-btns {

}

.manage-notes-note-info {
    width: 70%;
    margin: 0 auto;
}

.manage-notes-list-header {
    display: flex;
    flex-direction: row;
    /*box-shadow: 2px 4px 3px -4px rgba(0,0,0,0.93);*/
    border-radius: 10px 10px 0 0;
    padding: 1rem 1rem;
    align-items: center;
    background-color: #1D4956;
    color: white;
    margin-top: 1rem;
}

.manage-notes-note-card {
    /*height: 8vh;*/
    cursor: pointer;
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid lightgray;
    margin: 0.5rem 0 1rem 0;
    box-shadow: 2px 4px 3px -4px rgba(0,0,0,0.93)
}


.manage-notes-note {
    overflow-y: auto;
    padding: 0.5rem 0 0 0.7rem;
    height: 15%;
    border: 1px solid lightgray;
}

.dropdown {
  z-index: 10000 !important;
}

.Modal__overlay {
    z-index: 10000 !important;
}

.drag-active {
  border: 2px dashed #ccc;
}

.drop-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: lightgray;
    height: 22.9vh;
}