.data-source-section {
    padding: 1% 10% 3% 5%;
}

.data-source-btns {
    display: flex !important;
    flex-direction: row !important;
    justify-content: right !important;
    gap: .5rem;
}

.data-source-btn {
    background-color: #1c85e4 !important;
    margin-left: 2% !important;
    color: white !important;
    font-size: 0.5vw !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    min-width: 100px !important;
    /*height: 5vh !important;*/
    width: 10%;
    /*border-radius: 15%/50% !important;*/
    border-radius: 12px !important;
    /*padding: 0 12px 0 12px !important;*/
}

.data-source-btn:hover {
    box-shadow: none !important;
}

.data-source-table-section {
    width: 90%;
    margin: 3% auto;
}

.select-data-source-table-section {
    height: 50vh;
    max-width: 90vw;
}

.table-container{
    max-height: 55vh;
    overflow-y: scroll;
}


.table-container::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: rgb(224,224,224);
    border-radius: 1rem;
}

.create-button {
    min-width: 170px !important;
    width: 13%;
}

.standard-row{
    background-color: #d2d2d2;
}

.standard-row:hover{
    background-color: #d2d2d2 !important;
}
