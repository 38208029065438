
.manage-follow-ups-sidebar {
    width: 23%;
    padding: 1rem;
    overflow-y: auto;
}

.manage-follow-ups-sidebar::-webkit-scrollbar {
    height: .4em;
    width: .3em;
}

.manage-follow-ups-sidebar::-webkit-scrollbar-thumb {
    background-color: lightslategray;
    border-radius: 1rem;
}

.manage-follow-ups-card {
    /*height: 8vh;*/
    cursor: pointer;
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid lightgray;
    margin: 0.5rem 0 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/*.custom-table .MuiTableCell-root {*/
/*  border-bottom-color: red; */
/*}*/

.manage-follow-ups-table-dropdown-container {
    border-bottom: 1px solid #e0e0e0;
}

/*.manage-follow-ups-table-dropdown-container:last-child {*/
/*    border-bottom: none;*/
/*}*/