.mass-assign-body {
    display: flex;
    width: 90vw;
    height: 60vh;
    gap: .5rem;
    overflow: auto;
}

.mass-assign-half {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.table-controls-row {
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
}