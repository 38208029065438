.project-page-home {
    position: fixed;
    display: flex;
    width: 100%;
}

.block-header {
    background-color: lightgray;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-header p {
    font-size: 24px;
}

.follow-ups-block {
    width: 27%;
    border-right: 2px #9d9999 solid;

}

.follow-up-block {
    display: flex;
    border-bottom: 2px #9d9999 solid;
    height: 72px;
    align-items: center;
}

.dots-icon {
    flex: 1;
    width: 48px;
    height: 48px;
}


.data-object-summary-block {
    width: 100%;
}

.data-object-options {
    gap: .5rem;
    padding: 1%;
    display: flex;
    align-items: center;
    margin-left: 6%;
    margin-right: 7%;
}

.show-all-objects-btn {
    font-weight: bold;
    padding: 0.5% 1% 0.5% 1%;
    margin-left: 2%;
    border: none;
    border-radius: 4%/16%;
    background-color: #d9d9d9;
}

.data-object-tooltip {
    margin-left: auto;
    border-radius: 50%;
    background-color: #f1f1f1;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-object {
    width: 85%;
    height: 3.5%;
    margin: 0 auto;
    background-color: #f1f1f1;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
    align-items: center;
}

.follow-up-num {
    flex: 1;
    font-size: 28px;
    color: #f85000;
}

.data-objects-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    margin: auto;
    height: 70vh;
    overflow-y: scroll;
    padding: 3px;
}

.data-objects-block::-webkit-scrollbar {
    width: .4em;
}

.data-objects-block::-webkit-scrollbar-thumb {
    padding-right: 1rem;
    background-color: #3c3d3f;
    border-radius: 1rem;
}

