.files-upload-block{
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    width: 80%;
    margin: 5px auto;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.material-sto-block{
    display: flex;
    margin-bottom: 5px;
    gap: 2rem;
}