.select-source-system-section {
    padding: 1% 2.5% 1% 2%;
    margin-left: 5%;
    width: 80%;
    border: 1px solid gray;
    border-radius: 1rem;
    transition: 0.3s;
}

.system-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source-system-arrow {
    width: 5rem !important;
    height: 5rem !important;
}

.source-system-arrow:hover {
    cursor: pointer;
}

.card-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, 281px);
    grid-gap: 2rem;
    overflow: auto;
    max-height: 40vh;
    padding: 10px;
    justify-content: space-evenly;

}

.card-section::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.card-section::-webkit-scrollbar-thumb {
    background-color: rgb(224, 224, 224);
    border-radius: 1rem;
}

.source-card-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px !important;
}

.select-target-system-section {
    margin-top: 2%;
    padding: 1% 2.5% 1% 2%;
    margin-left: 5%;
    width: 80%;
    border: 1px solid gray;
    border-radius: 1rem;
    transition: 0.3s;
}

.select-btn {
    background-color: #007bff !important;
    width: 8%;
    height: 50%;
    color: white !important;
    border-radius: 1rem !important;
    margin-left: auto !important;
    margin-top: auto !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.select-btn:hover {
    box-shadow: none;
}

.selected-system {
    padding: 0 1.3% 0 3%;
    background-color: #c5daf2;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 1%;
}

.selected-system-img {
    width: 38px;
    height: 38px;
}

.selected-system-label {
    margin-left: 2%;
}

.check-icon {
    width: 38px;
    height: 38px;
    background-color: #1e86e5;
    border-radius: 50%;
    margin-left: auto;
}

.change-btn {
    color: black;
    border-radius: 20%/50% !important;
}

.modal-section {
    padding: 3%;
    display: flex;
    flex-direction: column;
}

.system-modal-textfield {
    margin-bottom: 2% !important;
}

.system-modal-textfield-description {
    margin-top: 5% !important;
}

.modal-btn-section {
    width: 100%;
    display: flex;
    margin-top: 5%;
    justify-content: space-between;
}

.error-msg {
    padding: 0 0 1% 5%;
    color: red;

    margin: 0 auto;
}

