.mark-target-fields-key-img {
    width: 16px;
    height: 16px;
    margin-left: 1rem;
    margin-bottom: -0.2rem;
    cursor: pointer;
}

.mark-target-fields-settings-img {
    margin-bottom: -0.4rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

.column-blue {
    background-color: #1D4956;
    color: white;
    font-size: 25px;
}

.column-orange {
    background-color: #613FEBFC;
    color: white;
    font-size: 25px;
}
