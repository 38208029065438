.users-block {
    display: grid;
    grid-template-columns: repeat(auto-fill, 290px);
    grid-gap: 2rem;
    max-height: 50vh;
    overflow: auto;
    padding: 10px;
    justify-content: space-evenly;
}

.users-block::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.users-block::-webkit-scrollbar-thumb {
    background-color: rgb(224, 224, 224);
    border-radius: 1rem;
}


.card-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px !important;
}