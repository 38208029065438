.display-source-data-modal-window {
    display: flex;
    width: 90vw;
    height: 70vh;
    gap: 3rem;
    flex-direction: column;
}

.display-source-data-btn-container {
    margin-top: 1%;
    margin-right: 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.5rem
}

.display-source-data-btn {
    width: 13%;
    min-width: 200px !important;
}

.data-grid-icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-evenly;*/
    width: 80% !important;
    gap: 0.5rem
}
.data-grid-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.display-source-grid-container {
    width: 90%;
    margin: 0 auto;
}

.close-and-save-btn {
    width: 10%;
    margin-left: auto !important;
    margin-right: 5% !important;
}


