.main-page-project-name-section {
    padding: 1% 10% 3% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-page-select-container {
    padding: 1% 10% 3% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.main-page-select {
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem
}

.project-detail-date-picker-container {
    margin-bottom: 2%;
}

.project-detail-timezone-container {
    width: 100%;
}