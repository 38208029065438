.display-mapping-differences-main {
    width: 70vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;

}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.display-mapping-differences-update-btn {
    display: flex !important;
    width: 15%;
    margin: 1rem 0 0 auto !important;
}