.autofill-mapping-main {
    width: 70vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.autofill-mapping-update-btn {
    display: flex !important;
    width: 15%;
    margin: 1rem 0 0 auto !important;
}