.change-load-template-main {
    display: flex;
    flex-direction: row;
    padding: 1%;
    height: 60vh;
}

.upload-new-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1%;
    width: 30vw;
    min-width: 400px;
}

.choose-active-template {
    padding: 0 2%;
    height: 50%;
}

.change-template-dropzone-area {
    width: 70%;
    margin: 1rem auto;
}