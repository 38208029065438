.manage-load-data-main {
    height: 70vh;
    width: 75vw;
    padding: 1.5rem;
    min-width: 1300px;
}

.manage-load-data-error-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5rem;
    background-color: #fdeded;
    width: 20%;
    min-width: 320px;
    padding: 0.4rem;
    justify-content: space-evenly;
}

.manage-load-data-tables-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 93%;
}

.manage-load-data-table-container {
    width: 50%;
}

.manage-load-data-header {
    margin-bottom: 1%;
}

.manage-load-data-table {
    margin: 0 auto;
}

.manage-load-data-file-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}

.manage-load-data-file-header-btn-container {
    display: flex;
    gap: 1rem;
}

.manage-load-data-table-icon {
    width: 25px;
    cursor: pointer;
}

.manage-load-data-file-table-icon {
    margin: 0 auto;
}
