.assign-dataset-container {
  display: flex;
  flex-direction: column;
  padding: 1%;
  width: 33vw;
}

.assign-dataset-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1%;
}

.assign-dataset-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  width: 60%;
  align-items: center;
  gap: 1rem;
}

.assign-dataset-row-lbl {
  margin-left: 8rem;
}

.assign-dataset-img {
  width: 24px;
  height: 24px;
}

.assign-dataset-close-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.assign-dataset-select-container {
  width: 30%;
  margin-top: 1% !important;
}

.assign-dataset-btn {
  width: 20%;
  margin-top: 3% !important;
  margin-left: auto !important;
  margin-right: 2% !important;
}
