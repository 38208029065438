.modal-container{
    background-color: #fff;
    border-radius: 8px;
    min-width: 15vw;
    padding: 10px;
}

.modal-container header{
    display: flex;
    justify-content: space-between;
    align-items: center;

}