.users-list-el{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-modal{
    overflow: auto;
    max-height: 25vh;
}

.add-modal::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.add-modal::-webkit-scrollbar-thumb {
    background-color: rgb(224,224,224);
    border-radius: 1rem;
}

.text-field-add{
    display: flex;
    align-items: center;
    justify-content: space-between;
}