.ingestion-error-accordion-container {
    width: 45vw;
    max-height: 70vh;
}

.ingestion-error-accordion-scroll {
    overflow-y: auto;
}

.ingestion-error-accordion-summary {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.ingestion-error-accordion-details-header {
    margin-bottom: 0.5rem !important;
}

.ingestion-error-accordion-error-msg {
    max-height: 4vh;

    overflow-y: scroll;
    margin-bottom: 3rem !important;
}

.ingestion-error-accordion-scroll::-webkit-scrollbar {
    width: 5px;
}

.ingestion-error-accordion-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.ingestion-error-accordion-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.ingestion-error-accordion-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}
