.edit-lookup {
    width: 85vw;
    min-width: 1500px;
    height: 76vh;
    display: flex;
    flex-direction: column;
}

.edit-lookup-main {
    display: flex;
    flex-direction: row;
}

.edit-lookup-instructions-container {
    padding: 0 0 0 1%;
    width: 35%;
}

.edit-lookup-instructions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.edit-lookup-btns-container {
    margin-top: 1%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1%;
}

.edit-lookup-text-field {
    width: 100% !important;
}

.edit-lookup-table-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.edit-lookup-btn {
    width: 20%;
}

.edit-lookup-warrning-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1rem;
    background-color: #fff4e5;
    margin-right: 3rem;
    width: 40%;
    gap: 2rem;
    min-width: 300px;
}

.system-select-input-label {
    left: -0.8rem;
    margin-top: 0.4rem;
}