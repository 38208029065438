.landing-page-header {
    z-index: 1;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6vh;
    background-color: #000;
    top:0

}

.landing-page-logo {
    width: 34px;
    margin-left: 0.5%;
    cursor: pointer;
}

.landing-page-menu {
    position: absolute;
    right: 0.5%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.landing-page-question-mark {
    border: 2px solid white;
    border-radius: 50%;
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-line {
    border-left: 2px solid white;
    height: 3vh;
}

.user-profile-section {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #ffc700;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.user-profile-img {
    width: 24px;
    height: 24px;
}

.landing-page-options{
    flex: 2;
    display: flex;
    align-items: center;
    margin-left: 1.5%;
    gap:0.5rem;
}

.pages-container{
    height: 100%;
    flex:10;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem
}

.landing-page-header h2{
    color: #fff;
}

.page{
    display: flex;
    align-items: center;
    height: 80%;
    color: gray;
    font-size: 24px;
    padding: 0 1rem;
    transition: .3s;
}

.active{
    color: #fff;
    border-bottom: #fff 2px solid;
}

.page:hover{
    color: #fff;
    background-color: #676666;
    cursor: pointer;
}

.header-block{
    height: 100%;

    width: calc(100% - 140px);
    display: flex;
    align-items: center;
}

