
.projects-search-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 26rem);
    justify-content: space-evenly;
    grid-gap: 2rem;
    gap: 2vw;
}

.new-project-add {
    position: absolute;
    right: 10%;
    bottom: 20%;
    color: #007bff;
    font-size: 96px;
    font-weight: lighter;
    margin: 0;
    cursor: pointer;
    transition: .13s;
}

.new-project-add:hover {
    font-weight: normal;
}

.new-project-tag {
    background-color: #007bff;
    padding: 4px 6px;
    width: fit-content;
    border-radius: 15%/50%;
}

.project-section {
    margin: 10px 0 0 20px;
}

.all-projects-label {
    margin-right: 1%;
}

.landing-page {
    overflow-y: auto;
    height: 93vh;

}

.landing-page::-webkit-scrollbar {
    display: none;
}

.landing-page::-webkit-scrollbar-thumb {
    display: none;
}