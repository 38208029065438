.manage-data-objects {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
}
.manage-objects-sidebar {
    width: 20%;
    min-width: 300px;
    border-right: 1px solid black;
    /*height: 100vh;*/
    min-height: 100vh;
    /*overflow-y: auto;*/
}

.manage-objects-sidebar-header {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;
}

.manage-objects-header {
    margin: 0;
    font-weight: bold;
    flex: none !important;
    background-color: #D9D9D9;
}

.manage-objects-main-window {
    height: 100vh;
    width: 100%;
}

.manage-objects-main-window-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #D9D9D9;
    height: 5vh;
    padding-left: 1%;
    gap: 1rem;
    padding-bottom: 0.5rem;
}
.manage-objects-main-window-header > p {
    font-size: 1.5vw;
}

.accordion-lbl {
    background-color: #b7b9b5;
}

.accordion-item {
    background-color: #F6F6F6;
}
.accordion-item:hover {
    cursor: pointer;
}

.accordion-selected-item {
    background-color: #4A6D77;
    color: white;
}

.selected-data-object {
    background-color: #DEDEDE !important;
}

.manage-objects-description {
    display: flex;
    align-items: baseline;
    width: 90%;
    margin-left: 3rem;
    margin-top: 1%;
}

.data-object-description-field {
    width: 100%;
}

.template-object-section {
    border: 1px solid #D9D9D9;
    width: 96%;
    margin: 2% auto;
}

.template-objects-header-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.5%;
}

.template-objects-header-container {
    font-size: 1.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5vh;
    padding-left: 1%;
    width: 37%;
    gap: 0.5rem;
}

.template-objects-header-label {
    margin: 0;
    font-weight: bold;
    flex: none !important;
}

.template-object-header-btn-section {
    display: flex;
    width: 50%;
    margin-left: 20%;
    justify-content: space-evenly;
}

.template-object-headers-btn {
    min-width: 200px !important;

}

.template-object-data-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2%;
}

.template-object-input-container {
    width: 35%;
}

.template-object-link {
    margin-top: 4% !important;
    width: 100%;
}

.template-object-table-container {
    width: 50%;
}

.template-object-btns-section {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-bottom: 5%;
}

.template-object-btns-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.template-object-btn-label {
    font-size: 1.5vw;
}

.template-object-btn {
    min-width: 300px;
    width: 100%;
    margin: 0 0 5% 0 !important;
}

.manage-objects-data-objects-container {
    height: 89.5vh;
    overflow-y: auto;
}

.manage-objects-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 0.5rem;
}