.manage-lookups-btn-container {
    margin-top: 1%;
    margin-right: 2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem
}

.manage-lookups-btns {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.manage-lookups-search-field {
    margin-bottom: 0.5% !important;
    margin-left: 3% !important;
    width: 15%;
}

.manage-lookups-close-and-save-btn {
    width: 10%;
    margin-left: auto !important;
    margin-right: 2.5% !important;
}

.manage-lookups-grid-container {
    width: 95%;
    margin: 0 auto;
}