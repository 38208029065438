.stepper{
    padding-left: 30px;
    width: 89%;
}

.main {
    display: flex;
    width: 100%;
}

.main-page {
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;

}

.main-page-header {
    padding: 3% 10% 1% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.main-page-btns {
    padding: 2% 10% 3% 5%;
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: .5rem;
}

.main-page-btn {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    min-width: 100px !important;
    width: 8%;
    border-radius: 5%/20% !important;
}

.main-page-btn:hover {
    box-shadow: none !important;
}

.complete-button{
    background-color: #007bff !important;
    color: white !important;
    margin-left: 1% !important;
}

.close-button{
    background-color: #f1f1f1 !important;
    margin-left: auto !important;
}