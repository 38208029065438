.data-scope-page{
    position: fixed;
    width: 90%;
    padding-left: 5%;
}

.control-buttons{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 2rem;
}