.manage-error-logs-main {
    display: flex;
    flex-direction: row;
    height: 90%;
    padding: 1rem;
}

.manage-error-logs-btns-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.manage-error-logs-upload-file-container {
    width: 50%;
    min-width: 600px;
}

.manage-error-logs-dropzone {
    width: 80%;
    margin: 0 auto;
}
