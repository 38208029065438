.main-body{
    display: flex;
    justify-content: space-around;
    width: 50vw;
}

.table-parent-block{
    width: 45%;
}

.dropzone-area {
    margin: 15px 0;
    display: flex;
    width: 90%;
    height: 300px;
    align-items: center;
    justify-content: center;
    border: 1px dashed gray;
}

.file-upload-block{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.datagrid{
    max-height: 400px;

}