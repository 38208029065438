
.table-container{
    max-height: 60vh;
    overflow-y: scroll;
}

.table-container::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: rgb(224,224,224);
    border-radius: 1rem;
}

.skip-button {
    width: 10%;
    margin-left: 1% !important;
    background-color: #f1f1f1 !important;
    min-width: 130px !important;
}

.erroneous-cell{
    color: #d32f2f;
    border-color: #d32f2f !important;
}