.mapping-view-note {
    overflow-y: auto;
    /*padding: 0.5rem 0 0 0.7rem;*/
    height: 15%;
    border: 1px solid lightgray;
}

.mapping-view-note-content {
    padding: 0.5rem 0 0 0.7rem;
}

.mapping-view-warning-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1rem;
    background-color: #fff4e5;
    margin-right: 3rem;
    width: 40%;
    gap: 2rem;
    min-width: 300px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.mapping-view-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1rem;
    background-color: #E5F6FD;
    margin-right: 3rem;
    width: 40%;
    gap: 2rem;
    min-width: 300px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.mapping-view-option-arrow {
    width: 10px;
    height: 14px;
    cursor: pointer;
}